.connectBtnBox {
  .MuiButton-root {
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins-Medium";
    height: 36px;
    border-radius: 10px;
  }

  .MuiButton-contained {
    background-color: transparent;
    border: 1px solid #9b96ff;
    color: #9b96ff;
    &:hover {
      background-color: #4540aa;
      border: 1px solid #4540aa;
      color: #ffffff;
      .downIcon {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}
.confirmBtnBox {
  .MuiButton-root {
    font-size: 16px;
    font-weight: bold;
    font-family: "Poppins-ExtraBold";
    height: 70px;
    max-height: 70px;
    width: 100%;
    border-radius: 10px;
  }
  .MuiButton-contained {
    background-color: #fca149;
    color: #ffffff;
    &:hover {
      background-color: #fca149;
    }
  }
  .MuiButton-contained.Mui-disabled {
    background-color: #fca149;
    &:hover {
      background-color: #fca149;
    }
  }
}

@media only screen and (max-width: 1080px) {
}
