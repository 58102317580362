.header-view {
  height: 90px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  .header {
    height: 100%;

    .logo {
      margin-left: 28px;
    }
    .connectBtnBox {
      margin-right: 28px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .header-view {
    .header {
      .logo {
        margin-left: 20px;
      }
      .connectBtnBox {
        margin-right: 20px;
      }
    }
  }
}
